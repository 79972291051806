import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { withPreview } from 'gatsby-source-prismic';

import OnImagesLoaded from 'react-on-images-loaded';
import Overlay from '../components/shared/overlay';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';
import Wheel from '../components/beers/wheel';
import Divide from '../components/shared/divide';

const StyledDiv = styled.div`
	margin-top: -1rem;
`;

const seltzers = [
	{
		slug: "/seltzers/robots-kiss-butterflies",
		name: "Robots Kiss Butterflies",
		type: "Hard Seltzer",
		can: "/img/seltzers/SeltzerWorld_Cans_0002_RKB.png",
		bg: "/img/seltzers/SeltzerWorld_RKB_BG.png"
	},
	{
		slug: "/seltzers/space-born-unicorn",
		name: "Space Born Unicorn",
		type: "Hard Seltzer",
		can: "/img/SeltzerWorld_Cans_0003_SBU-update.png",
		bg: "/img/seltzers/SeltzerWorld_SBU_BG.png"
	},
	{
		slug: "/seltzers/supersonic-puppy-dreams",
		name: "Supersonic Puppy Dreams",
		type: "Vodka Craft Seltzer",
		can: "/img/seltzers/SeltzerWorld_Cans_0001_SSPD-update.png",
		bg: "/img/seltzers/SeltzerWorld_SSPD_BG.png"
	},
	{
		slug: "/seltzers/tangerine-fuzz-machine",
		name: "Tangerine Fuzz Machine",
		type: "Hard Seltzer",
		can: "/img/seltzers/SeltzerWorld_Cans_0000_TFM-update.png",
		bg: "/img/seltzers/SeltzerWorld_TFM_BG.png"
	},
	{
		slug: "/seltzers/robots-kiss-butterflies",
		name: "Robots Kiss Butterflies",
		type: "Hard Seltzer",
		can: "/img/seltzers/SeltzerWorld_Cans_0002_RKB.png",
		bg: "/img/seltzers/SeltzerWorld_RKB_BG.png"
	},
	{
		slug: "/seltzers/space-born-unicorn",
		name: "Space Born Unicorn",
		type: "Hard Seltzer",
		can: "/img/SeltzerWorld_Cans_0003_SBU-update.png",
		bg: "/img/seltzers/SeltzerWorld_SBU_BG.png"
	},
	{
		slug: "/seltzers/supersonic-puppy-dreams",
		name: "Supersonic Puppy Dreams",
		type: "Vodka Craft Seltzer",
		can: "/img/seltzers/SeltzerWorld_Cans_0001_SSPD-update.png",
		bg: "/img/seltzers/SeltzerWorld_SSPD_BG.png"
	},
	{
		slug: "/seltzers/tangerine-fuzz-machine",
		name: "Tangerine Fuzz Machine",
		type: "Hard Seltzer",
		can: "/img/seltzers/SeltzerWorld_Cans_0000_TFM-update.png",
		bg: "/img/seltzers/SeltzerWorld_TFM_BG.png"
	},
];

const PageTemplate = ({ data }) => {
	const [isLoaded, setIsLoaded] = useState(true);

	return (
		<Layout isSeltzer={true}>
			<SEO title="Infinite Minds Craft Seltzers" />
			<Overlay isShown={isLoaded} />
			<OnImagesLoaded
				onLoaded={() => setIsLoaded(false)}
				onTimeout={() => setIsLoaded(false)}
				timeout={10000}
			>
				<Wheel beers={seltzers} isSeltzer={true}/>
			</OnImagesLoaded>
			<StyledDiv>
				<Divide />
			</StyledDiv>
		</Layout>
	);
};

export default withPreview(PageTemplate);
